<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Thêm bảo trì ngân hàng</h6>
        </template>
        <div class="content">
          <b-row>
            <b-col class="col-md-6">
              <b-form-group label="Tiêu đề (*)">
                <b-form-input v-model="params.note"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="col-md-6">
              <b-form-group label="Loại liên kết (*)">
                <b-form-select v-model="params.link_type" :options="linkType"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="col-md-6">
              <b-form-group label="Thời gian bắt đầu(*)">
                <date-picker
                    id="end_date"
                    v-model="params.start_time"
                    type="datetime"
                    placeholder="Chọn thời gian"
                    :input-attr="{required: 'true',name: ''}"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-type="format"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col class="col-md-6">
              <b-form-group label="Thời gian kết thúc(*)">
                <date-picker
                    id="end_date"
                    v-model="params.end_time"
                    type="datetime"
                    placeholder="Chọn thời gian"
                    :input-attr="{required: 'true',name: ''}"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-type="format"
                ></date-picker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="col-md-6">
              <b-form-group label="Ngân hàng (*)">
                <multiselect v-if="object" v-model="params.bank_id" :options="object" :multiple="true" :taggable="true" label="text" track-by="text"></multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-center">
                <b-button type="button" variant="primary" class="mr-2" @click="createBankMaintenance()"><i class="fas fa-save"></i> Lưu</b-button>
                <b-button type="button" variant="danger" @click="cancel()"><i class="fas fa-times"></i> Hủy</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "../../../../core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

Vue.component("multiselect", Multiselect)
Vue.use(Notifications);

const CmsRepository = RepositoryFactory.get("cms");

export default {
  mixins: [Common],
  components: { Multiselect, DatePicker },
  data() {
    return {
      items: {},
      params: {
        note: null,
        bank_id: [],
        link_type: null,
        start_time: null,
        end_time: null
      },
        bank_params: {
            per_page:100
        },
      object: [],
      linkType: [
        { text: "Tất cả", value: "ALL" },
        { text: "LK trực tiếp", value: "DIRECT_LINK" },
        { text: "LK Napas", value: "NAPAS_PAYGATE" },
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thêm bảo trì ngân hàng", route: "create" },
    ]);
  },
  methods: {
    async createBankMaintenance() {
      this.$bus.$emit("show-loading", true);

      if (this.params.bank_id.length <= 0) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("warn", "Bank không được để trống");
        return false;
      }

      if (!this.params.note) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("warn", "Tiêu đề không được để trống");
        return false;
      }

      if (!this.params.link_type) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("warn", "Loại liên kết không được để trống");
        return false;
      }

      if (!this.params.start_time || !this.params.end_time) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("warn", "Thời gian không được để trống");
        return false;
      }

      let params = this.convert(this.params);
      let response = await CmsRepository.createBankMaintenance(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code > 0) {
        this.notifyAlert("warn", body.message);
        return true;
      }

      this.notifyAlert("success", body.message);
      window.location.href = "#/tools/bank/maintenance";
    },
    async getBankProvider() {
      this.object = [];
      this.$bus.$emit("show-loading", true);

      let response = await CmsRepository.listBank(this.bank_params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code > 0) {
        this.notifyAlert("warn", body.message);
        return true;
      }

      let banks = body.data.data;
      let object = [];
      banks.forEach(function(item) {
        object.push({ value: item.id, text: item.bank_code + ` - (${item.full_name})` });
      });

      this.object = object;
    },
    cancel() {
      window.location = "/#/tools/segment/action";
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
  },
  created() {
    this.getBankProvider();
  },
  watch: {

  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-datepicker/index.css"></style>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>
